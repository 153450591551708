import React, { useState, createContext, useContext } from "react";
import Snackbar from "@mui/material/Snackbar";
import { Button, IconButton } from "@mui/material";
import sendErrorLogsToServer from "../utility/sendErrorLogsToServer";
import CloseIcon from "@mui/icons-material/Close";
import errorParsing from "../utility/errorParsing";
import config from "../config";
// create context
export const AdvisorContext = createContext();

// provider
export const AdvisorContextProvider = ({ children }) => {
 const [advisorState, setadvisorState] = useState({
  portfolioID: "",
 });

 return <AdvisorContext.Provider value={{ advisorState, setadvisorState }}>{children}</AdvisorContext.Provider>;
};

// use context
export const useAdvisorContext = () => {
 const advisor = useContext(AdvisorContext);
 return advisor;
};
