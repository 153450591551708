import { createSlice } from "@reduxjs/toolkit";

const initialState = {
 value: {
  autoCompleteValue: "",
  rebalance_portfolionameOptions: {},
 },
};

export const persistRandomData = createSlice({
 name: "persistRandomData",
 initialState,
 reducers: {
  addData: (state, action) => {
   state.value.autoCompleteValue = action.payload;
  },
  removeData: (state, action) => {
   state.value.autoCompleteValue = action.payload;
  },
  add_Rebalance_portfolioname: (state, action) => {
   //{label:"", value:""}
   state.value.rebalance_portfolionameOptions = action.payload;
  },
 },
});

// Action creators are generated for each case reducer function
export const { addData, removeData, add_Rebalance_portfolioname } = persistRandomData.actions;

export default persistRandomData.reducer;
