import apiClient from "../../../common/http/http-investor";
import config from "../../../config.js";
import { errorCodeHandling } from "../../../utility/commonErrorCodeHandling";

export async function getDashboardListAPI(requestToken, clientID) {
 try {
  const response = await apiClient.get(`/client/${clientID}/subscription`, {
   headers: {
    "Authorization": `Bearer ${requestToken}`,
   },
  });
  return errorCodeHandling(response);
 } catch (error) {
  return errorCodeHandling(error.response, [error.config.method, `${error.config.baseURL}${error.config.url}`]);
 }
}

export async function getRiskProfileListAPI(requestToken, clientID) {
 try {
  const response = await apiClient.get(`/client/${clientID}/serviceType/${config.serviceType}/riskprofile`, {
   headers: {
    "Authorization": `Bearer ${requestToken}`,
   },
  });
  return errorCodeHandling(response);
 } catch (error) {
  return errorCodeHandling(error.response, [error.config.method, `${error.config.baseURL}${error.config.url}`]);
 }
}

export async function getOrderBookListAPI(requestToken, clientID) {
 try {
  const response = await apiClient.get(`/client/${clientID}/investment/orders`, {
   headers: {
    "Authorization": `Bearer ${requestToken}`,
   },
  });
  return errorCodeHandling(response);
 } catch (error) {
  return errorCodeHandling(error.response, [error.config.method, `${error.config.baseURL}${error.config.url}`]);
 }
}

export async function getInvestmentListAPI(requestToken, clientID) {
 try {
  const response = await apiClient.get(`/client/${clientID}/investment?take=50`, {
   headers: {
    "Authorization": `Bearer ${requestToken}`,
   },
  });
  return errorCodeHandling(response);
 } catch (error) {
  return errorCodeHandling(error.response, [error.config.method, `${error.config.baseURL}${error.config.url}`]);
 }
}

export async function getPortFolioDataDetailsAPI(requestToken, clientID, portfolioID) {
 try {
  const response = await apiClient.get(`/client/${clientID}/investment/portfolio/${portfolioID}/holding?take=50`, {
   headers: {
    "Authorization": `Bearer ${requestToken}`,
   },
  });
  return errorCodeHandling(response);
 } catch (error) {
  return errorCodeHandling(error.response, [error.config.method, `${error.config.baseURL}${error.config.url}`]);
 }
}

export async function getSubDomainExistAPI(subDomain) {
 const params = {
  domain: subDomain,
 };
 try {
  const response = await apiClient.get(`/public/advisor/domain/exists`, {
   params,
  });
  return errorCodeHandling(response);
 } catch (error) {
  return errorCodeHandling(error.response, [error.config.method, `${error.config.baseURL}${error.config.url}`]);
 }
}
