import loggingErrorUtility from "./loggingErrorUtility";

const catchErrorMsg = (error, pageName, context, errorFrom = null) => {
 const errorMessage = error?.stack ? error?.message + "_" + error?.stack : error?.message;

 if (context && context.setPopDetails) {
  context.setPopDetails((prv) => ({ ...prv, state: true }));
 } else if (this.props.prop && this.props.prop.setPopDetails) {
  this.props.prop.setPopDetails((prv) => ({ ...prv, state: true }));
 }

 if (errorFrom === "errorboundry") {
  loggingErrorUtility().ErrorLogs(
   {
    message: error?.stack ?? error?.message,
    status: "",
    fileName: pageName?.page,
   },
   "",
   "",
  );
 } else {
  loggingErrorUtility().ErrorLogs({ message: errorMessage, status: "" }, "", "", pageName);
 }
};

export default catchErrorMsg;
