import React, { lazy, Suspense, useState } from "react";
import { BrowserRouter, Navigate, Outlet, Route, Routes } from "react-router-dom";
import "./assets/css/customer/scss/main.scss";
import "./assets/scss/main.scss";
import { UserProvider } from "./store/usercontext";
import "./App.css";
import { domainList } from "./assets/messages/domainList";
import { AdvisorContextProvider } from "../src/store/advisorContext";
import Loader from "./components/mainLoader";
import { TenantNestedMenu } from "./components/TenantNestedMenu";
import DashboardService from "./controller/service/customers/DashboardService";
import { useModalContext } from "./store/errorModalContext";
import ScrollToTop from "./components/ScrollToTop";
import catchErrorMsg from "./utility/commonCatchError";

const Sidemenu = lazy(() => import("./common/menu/sidemenu"));
const SidemenuDistributor = lazy(() => import("./common/menu/sidemenuDistributor"));
const SidemenuAgent = lazy(() => import("./common/menu/sidemenuAgent"));
const Signup = lazy(() => import("./view/tanents/signup"));
const Signin = lazy(() => import("./view/tanents/signin"));
const ForgotPassword = lazy(() => import("./view/tanents/forgotPassword"));
const ResetPassword = lazy(() => import("./view/tanents/resetPassword"));
const ChangePassword = lazy(() => import("./view/tanents/changePassword"));
const TokenExpired = lazy(() => import("./view/tanents/tokenExpired"));
const DistributorSignIn = lazy(() => import("./view/distributor/distributorSignIn"));
const MobileVerify = lazy(() => import("./view/tanents/mobileVerify"));
const VerifySignup = lazy(() => import("./view/tanents/verifySignup"));
const Home = lazy(() => import("./view/customer/home"));
const Portfolio = lazy(() => import("./view/customer/portfolio"));
const AdvisorDetails = lazy(() => import("./view/customer/advisordetails"));
const PortfolioDetails = lazy(() => import("./view/customer/portfoliodetails"));
const Payment = lazy(() => import("./view/customer/payment"));
const Termsconditions = lazy(() => import("./view/customer/termsconditions"));
const Privacypolicy = lazy(() => import("./view/customer/privacypolicy"));
const Disclaimer = lazy(() => import("./view/customer/disclaimer"));
const RefundPolicy = lazy(() => import("./view/customer/refundpolicy"));
const Knowledge = lazy(() => import("./view/customer/knowledge"));
const Faq = lazy(() => import("./view/customer/faq"));
const Joinus = lazy(() => import("./view/customer/joinus"));
const Distributor = lazy(() => import("./view/customer/distributor"));
const Publisher = lazy(() => import("./view/customer/publisher"));
const Dashboard = lazy(() => import("./view/customer/dashboard"));
const PageNotFound = lazy(() => import("./view/customer/404"));
const Logout = lazy(() => import("./view/tanents/LogoutUser"));

function App() {
    const context = useModalContext();
    const data = useModalContext();
    const [jwt, setJwt] = useState("");
    const value = { jwt, setJwt };
    // Check login session
    React.useEffect(() => {
        if (sessionStorage.getItem("jwt")) {
            setJwt(JSON.parse(sessionStorage.getItem("jwt")));
        }
    }, []);

    const dashboardService = new DashboardService();
    const PrivateWrapper = () => {
        return sessionStorage.getItem("jwt") === null ? (
            <Navigate to="/" />
        ) : Object.keys(JSON.parse(sessionStorage.getItem("jwt"))).length > 0 ? (
            <Outlet />
        ) : (
            <Navigate to="/" />
        );
    };
    return (
        <AdvisorContextProvider>
            <UserProvider value={value}>
                <div className="App">
                    <BrowserRouter>
                        <ScrollToTop />
                        <Suspense fallback={<Loader />}>
                            <Routes>
                                <Route path="signup" element={<Signup prop={data} />} />
                                <Route path="mobileVerify" element={<MobileVerify />} />
                                <Route path="verifySignup" element={<VerifySignup />} />
                                <Route path="/login" element={<Signin prop={data} />} />
                                <Route path="/forgotPassword" element={<ForgotPassword prop={data} />} />
                                <Route path="/resetpassword/:token" element={<ResetPassword />} />
                                <Route path="/changepassword" element={<ChangePassword />} />
                                <Route path="distributor-login" element={<DistributorSignIn prop={data} />} />
                                <Route path="/advisor" element={<Sidemenu />}>
                                    {TenantNestedMenu.map((ele, index) => (
                                        <Route key={`route-${index}`} path={ele.path} element={<ele.component />} />
                                    ))}
                                </Route>
                                <Route path="sidemenuDistributor" element={<SidemenuDistributor />} />
                                <Route path="sidemenuAgent" element={<SidemenuAgent />} />
                                <Route path="tokenExpired" element={<TokenExpired />} />
                                <Route path="home" element={<Home />} />
                                <Route path="/" element={<Home />} />
                                <Route path="portfolio" element={<Portfolio />} />
                                <Route path="advisordetails/:tenentId" element={<AdvisorDetails />} />
                                <Route path="/portfolio-details/:tenentId/:portfolioId" element={<PortfolioDetails />} />
                                <Route path="payment" element={<Payment />} />
                                <Route path="termsconditions" element={<Termsconditions />} />
                                <Route path="privacypolicy" element={<Privacypolicy />} />
                                <Route path="disclaimer" element={<Disclaimer />} />
                                <Route path="refundpolicy" element={<RefundPolicy />} />
                                <Route path="knowledge" element={<Knowledge />} />
                                <Route path="faq" element={<Faq />} />
                                <Route path="joinus" element={<Joinus />} />
                                <Route path="distributor" element={<Distributor />} />
                                <Route path="publisher" element={<Publisher />} />
                                <Route path="*" element={<PageNotFound />} />
                                <Route path="/Logout" element={<Logout />} />
                                <Route element={<PrivateWrapper />}>
                                    <Route path="/dashboard" element={<Dashboard />} />
                                </Route>
                            </Routes>
                        </Suspense>
                    </BrowserRouter>
                </div>
            </UserProvider>
        </AdvisorContextProvider>
    );
}

export default App;
