import errorParsing from "./errorParsing";
import getBrowserDetails from "./getBrowserDetails";
import config from "../config";

export default function loggingErrorUtility() {
 //Integrate ErrorLogs
 const ErrorLogs = async (data, url = "", method = null, extraOption = null) => {
  // Options for formatting the date and time
  const options = {
   timeZone: "Asia/Kolkata", // IST time zone
   hour12: false, // Use 24-hour format
   weekday: "long", // Full day name
   year: "numeric", // Full year
   month: "long", // Full month name
   day: "numeric", // Day of the month
   hour: "2-digit", // Two-digit hour
   minute: "2-digit", // Two-digit minute
   second: "2-digit", // Two-digit second
  };

  const browserDetails = sessionStorage.getItem("browser")
   ? sessionStorage.getItem("browser").split(",")
   : getBrowserDetails();

  const brokerURL = window.location.href;
  const idDetails = brokerURL?.split("/")?.slice(-2);

  if (data?.message) {
   if (data.message?.length > 1000) {
    data.message = data.message.slice(0, 999);
   }
  }

  data["browserName"] = browserDetails[0];
  data["browserVersion"] = browserDetails[1];
  data["dateTime"] = new Date().toLocaleString("en-IN", options);
  data["URL"] = url;
  data["method"] = method;
  data["email"] = JSON.parse(sessionStorage.getItem("jwt"))?.email ?? " ";
  data["mobile"] = JSON.parse(sessionStorage.getItem("jwt"))?.mobile ?? " ";

  data["loggedinID"] =
   (extraOption !== null && extraOption?.page?.split("_")[0] === "PortfolioDetails") ||
   brokerURL.includes("portfolio-details")
    ? Array.isArray(idDetails)
      ? idDetails[0]
      : JSON.parse(sessionStorage.getItem("jwt"))?.id ?? " "
    : JSON.parse(sessionStorage.getItem("jwt"))?.id ?? " ";
  data["portfoliId"] =
   (extraOption !== null && extraOption?.page?.split("_")[0] === "PortfolioDetails") ||
   brokerURL.includes("portfolio-details")
    ? Array.isArray(idDetails)
      ? idDetails[1]
      : " "
    : " ";
  data["fileName"] = extraOption !== null ? extraOption?.page : data?.fileName ? data.fileName : " ";

  // append dealerid
  if (brokerURL.toLowerCase().includes("dealer")) {
   data["loggedinID"] = JSON.parse(sessionStorage.getItem("jwt"))?.id ?? " ";
  }
  // append distributor id
  if (brokerURL.toLowerCase().includes("distributor")) {
   data["loggedinID"] = JSON.parse(sessionStorage.getItem("jwt"))?.id ?? " ";
  }

  // setting errors in local storage
  const storedData = JSON.parse(sessionStorage.getItem("errorLogs")) || [];

  storedData.push(data);
  sessionStorage.setItem("errorLogs", JSON.stringify(storedData));

  // send error automated
  if (config.popUp_Config === "true") {
   return null;
  } else {
   errorParsing();
  }

  return null;
 };

 return { ErrorLogs };
}
