import React, { useState, useEffect } from "react";

const MainLoader = () => {
 const [load, setLoad] = useState(0);

 useEffect(() => {
  const interval = setInterval(() => {
   setLoad((prevLoad) => prevLoad + 1);
  }, 150);

  return () => clearInterval(interval);
 }, []);

 const blurring = () => {
  if (load > 99) return;

  const loadText = `${load}%`;
  const loadOpacity = scale(load, 0, 100, 1, 0);
  const blurAmount = scale(load, 0, 100, 30, 0);

  return (
   <>
    <div className="main-loader">
     <div className="loading-text" style={{ opacity: loadOpacity }}>
      {loadText}
     </div>
     <div className="bg" style={{ filter: `blur(${blurAmount}px)` }} />
    </div>
   </>
  );
 };

 const scale = (num, in_min, in_max, out_min, out_max) => {
  return ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
 };

 return <>{blurring()}</>;
};

export default MainLoader;
