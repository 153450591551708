export default function getBrowserDetails() {
 const userAgent = navigator.userAgent;

 const test = (regexp) => {
  return regexp.test(userAgent);
 };

 // find browser version
 const appVersion = (pattern) => {
  return userAgent.match(pattern)[1];
 };

 if (test(/opr\//i) || !!window.opr) {
  return ["Opera", appVersion(/OPR\/(\S+)/)];
 } else if (test(/edg/i)) {
  return ["Microsoft Edge", appVersion(/Edg\/(\S+)/)];
 } else if (test(/chrome|chromium|crios/i)) {
  return ["Google Chrome", appVersion(/Chrome\/(\S+)/)];
 } else if (test(/firefox|fxios/i)) {
  return ["Mozilla Firefox", appVersion(/Firefox\/(\S+)/)];
 } else if (test(/safari/i)) {
  return ["Apple Safari", appVersion(/Version\/(\S+)/)];
 } else if (test(/trident/i)) {
  return ["Microsoft Internet Explorer", appVersion(/Trident\/(\S+)/)];
 } else if (test(/ucbrowser/i)) {
  return ["UC Browser", appVersion(/UCBrowser\/(\S+)/)];
 } else if (test(/samsungbrowser/i)) {
  return ["Samsung Browser", appVersion(/SamsungBrowser\/(\S+)/)];
 } else {
  return ["Unknown Browser", "Unknown Version"];
 }
}
