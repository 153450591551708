import axios from "axios";
import config from "../../config";
import Constants from "../../common/constant";
import mockAxios from "./mockAxios";

// Check if it's a testing environment
const isTestingEnvironment = process.env.NODE_ENV === Constants.APPLICATION_ENVIRONMENT.TEST;

// Export either mockAxios or axiosInstance based on the environment
export default isTestingEnvironment
 ? mockAxios
 : axios.create({
    baseURL: config.apiRootUrl,
    headers: {
     "Content-type": "application/json",
     "x-api-key": config.apiRootKeyTenant,
    },
   });
