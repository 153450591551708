import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ErrorModalContextProvider } from "./store/errorModalContext";
//import { QueryClient, QueryClientProvider } from "react-query";
import ErrorBoundary from "./utility/catchUnexpectedErrors";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { store } from "./store/redux/store";
import { Provider } from "react-redux";

ReactDOM.render(
 <React.StrictMode>
  <Provider store={store}>
   <ErrorModalContextProvider>
    <ErrorBoundary>
     <App />
    </ErrorBoundary>
   </ErrorModalContextProvider>
  </Provider>
  {/* <Loader /> */}
 </React.StrictMode>,
 document.getElementById("root"),
);
