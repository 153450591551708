const Constants = {
 INSERT: "Insert",
 UPDATE: "Update",
 VALID_EXTENSION: {
  ZIP: "zip",
  PDF: "pdf",
  CSV: "csv",
  XLSX: "xlsx",
  XLS: "xls",
 },
 APPLICATION_ENVIRONMENT: {
  TEST: "test",
 },
 REPORTS_TYPES: {
  Client: "client",
  Investment: "investment",
  Subscription: "subscription",
 },
 REPORTS_STATUS: {
  Completed: "Completed",
  Pending: "Pending",
  Rejected: "Rejected",
 },
 REPORTS_STATUS_CLASS_NAME: {
  Completed: "badge bg-primary-transparent",
  Pending: "badge bg-warning-transparent",
  Rejected: "badge bg-danger-transparent",
 },
 REPORTS_DOWNLOAD_CLASS_NAME: {
  Completed: "btn1 btn-primary btn-2",
  Pending: "btn1 btn-primary btn-2 btn-disable",
  Rejected: "btn1 btn-primary btn-2 btn-disable",
 },
 PAGINATION_TYPE: {
  PAGE_CHANGE: "PAGE_CHANGE",
  ROWS_PER_PAGE_CHANGE: "ROWS_PER_PAGE_CHANGE",
 },
 BOOKMARK_TYPE: {
  bookmark: "bookmark",
  page: "page",
 },
 EXCEL_EXTENSION: "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
 REPORT_SUMMARY: "/reportSummary",
};
export default Constants;
