export const errorCodeHandling = (response, apiInfo = [null, null]) => {
    if (response?.data?.message === "jwt expired" || response?.data?.message === "jwt malformed") {
        window.location.href = "/tokenExpired";
    }

    const messageByStatus = {
        "200": "Success",
        "400": "Bad Request",
        "401": "Unauthorized Access",
        "403": "Forbidden",
        "404": "Not Found",
        "409": "Conflict",
        "413": "File size should not be greater than 5MB",
        "429": "Too Many Request",
        "500": "Internal Server Error",
    };

    const isSuccess = response?.status === 200;

    const responseData = {
        status: response?.status,
        success: isSuccess,
        data: response?.data?.body || {},
        message: response?.data?.message || "",
        statuscommonmessage: messageByStatus[response?.status] || "Unknown Error",
        ...(isSuccess && { bookmark: response?.data?.body?.bookmark || "" }),
        ...(!isSuccess && { apiInfo }),
    };

    return responseData;
};
