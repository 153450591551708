import axios from "axios";
import config from "../../config";
import Constants from "../../common/constant";

const isTestingEnvironment = process.env.NODE_ENV === Constants.APPLICATION_ENVIRONMENT.TEST;
if (!isTestingEnvironment) {
 window.jest = { mock: () => {} };
}

jest.mock("axios");

axios.create({
 baseURL: config.apiRootUrl,
 headers: {
  "Content-type": "application/json",
  "x-api-key": config.apiRootKeyTenant,
 },
});

export default axios;
